<template>
  <v-navigation-drawer
    v-bind="$attrs"
    style="z-index: 1; margin-top: 64px"
    :mini-variant.sync="miniPanel"
    v-model="showPanel"
    mobile-break-point="960"
    :width="300"
    class="pb-0"
    permanent
    absolute
    :height="$parent.$el && $parent.$el.clientHeight && $parent.$el.clientHeight - 64"
    v-click-outside="minimizePanel"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-icon @click.stop="miniPanel = !miniPanel">fas fa-cogs</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>Фильтрация </v-list-item-title>

      <v-btn icon @click.stop="miniPanel = !miniPanel">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list den1se>
      <v-list-item link class="pl-2">
        <v-list-item-icon>
          <v-badge :value="users.length" :content="users.length" overlap>
            <v-icon large @click.stop="miniPanel = !miniPanel">mdi-account</v-icon>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-content>
          <v-autocomplete
            :disabled="!loaded"
            :items="listUsers"
            item-text="name"
            item-value="id"
            :value="users"
            @input="onSelectUser($event)"
            label=""
            hide-details
            dense
            class="mt-1 panel-element"
            multiple
            outlined
            deletable-chips
            small-chips
            :menu-props="{ contentClass: 'panel-element' }"
            return-object
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {},
  props: {
    listUsers: Array,
    loaded: Boolean,
    users: { type: Array, default: () => [] },
  },
  data() {
    return {
      showPanel: true,
      miniPanel: false,
      users1: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    minimizePanel(event) {
      // Проверяем, если панель открыта в большом режиме, то минимизируем её
      if (!this.miniPanel && !event.target.closest(".panel-element")) {
        this.miniPanel = true;
      }
    },
    onSelectUser(e) {
      console.log(e);
      this.$emit("selectUser", e);
    },
    getDate(d) {
      if (!d) return null;
      return new Date(d).toLocaleDateString("ru-RU");
    },
  },
};
</script>
